@import '@/scss/variables.scss';
@import '~vuetify/src/styles/styles.sass';

#container {
  background-color: #01002e;
  background-repeat: repeat;
}

.btn-size {
  min-width: 80%;
}

.counter {
  font-family: "Karasuma Gothic";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  letter-spacing: 0.75px;
  color: #5a6972;
}

textarea {
  resize: none;
}

.title-text {
  font-family: "Karasuma Gothic";
  font-style: normal;
  font-weight: 700;
  font-size: 47px;
  line-height: 50px;
  letter-spacing: 1px;
  color: #ffffff;
}

.box-title {
  font-family: "Karasuma Gothic";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 28px;
  letter-spacing: 0.75px;
  color: #ffffff;
}

.label {
  font-family: "Karasuma Gothic";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 28px;
  letter-spacing: 0.75px;
  color: #f4f5f6;
}

.more-offers-panel {
  background: #1d1c4c !important;
  backdrop-filter: blur(60px);
  border-radius: 12px !important;
  font-family: "Karasuma Gothic" !important;
  font-style: normal !important;
  font-weight: 700 !important;
  font-size: 20px !important;
  line-height: 28px !important;
  color: #f4f5f6 !important;
  margin-top: 0px !important;
  padding: 5px !important;
  letter-spacing: 0.75px !important;
}

.title-accordion-text {
  font-family: "Karasuma Gothic";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 28px;
  letter-spacing: 0.75px;
  color: #ffffff !important;
}

.subtitle-accordion-text {
  font-family: "Karasuma Gothic";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 28px;
  letter-spacing: 0.75px;
  color: #ffffff !important;
}

.input-field {
  padding: 12px 16px;
  border: 1px solid #e8ebed;
  border-radius: 12px;
  font-family: "Karasuma Gothic";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 28px;
  letter-spacing: 0.75px;
  color: #5a6972;
  width: 100%;
}

.input-field:focus {
  border: 1px solid #f39200;
}

.input-field::placeholder {
  color: #5a6972;
}
