@import '@/scss/variables.scss';
@import '~vuetify/src/styles/styles.sass';

#container {
  background-color: #01002e;
  background-repeat: repeat;
}

.btn-size {
  min-width: 80%;
}

@media only screen and (min-width: 900px) and (max-width: 1260px) {
  .container {
    max-width: 99vw;
  }
}

.footer-text {
  background-color: transparent !important;
  font-family: 'Karasuma Gothic';
  font-style: normal;
  font-weight: 400;
  font-size: 12.8px;
  line-height: 24px;
  letter-spacing: 0.75px;
  color: #98A6AE;
  padding-left: 0;
}

.label {
  font-family: "Karasuma Gothic";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 28px;
  letter-spacing: 0.75px;
  color: #C6CED2;
}

.input-field{
  padding: 12px 16px;
  border: 1px solid #E8EBED;
  border-radius: 12px;
  font-family: 'Karasuma Gothic';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 28px;
  letter-spacing: 0.75px;
  color: #5a6972;
  width: 100%;
}

.input-field:focus{
  border: 1px solid #f39200;
}

.input-field::placeholder{
  color: #5a6972;
}
